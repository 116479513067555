import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form as FinalForm } from 'react-final-form'
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl'
import { Alert, FieldTextInput, Form } from '../../components'

import css from './ContactUsForm.module.css'
import Button from '../../components/Button/Button'
import { sendSupportEmail } from '../../util/api'
import * as validators from '../../util/validators'

class ContactUs extends Component {
  constructor(props) {
    super(props)
    this.onSubmit = this.onSubmit.bind(this)
    this.state = {
      isEmailSent: false
    }
  }

  async onSubmit(values) {
    await sendSupportEmail(values)
    this.setState({ isEmailSent: true })
  }

  render() {
    return (
      <>
        {this.state.isEmailSent && (
          <Alert type="success">
            <FormattedMessage id="ContactUsPage.emailSent"></FormattedMessage>
          </Alert>
        )}
        <FinalForm
          {...this.props}
          onSubmit={this.onSubmit}
          render={(formRenderProps) => {
            const { handleSubmit, intl, submitting, invalid, pristine, form } = formRenderProps
            const emailInvalidMessage = intl.formatMessage({
              id: 'PasswordRecoveryForm.emailInvalid'
            })

            const helpLabel = intl.formatMessage({
              id: 'ContactUsPage.help'
            })

            const helpPlaceholder = intl.formatMessage({
              id: 'ContactUsPage.helpPlaceholder'
            })

            const emailLabel = intl.formatMessage({
              id: 'PasswordRecoveryForm.emailLabel'
            })

            const submitDisabled = invalid || pristine || submitting
            const required = validators.emailFormatValid(emailInvalidMessage)
            const minLength = validators.minLength('Min. 20 char', 20)

            return (
              <Form onSubmit={handleSubmit} className={css.form}>
                <FieldTextInput
                  id="email"
                  name="email"
                  type="text"
                  label={emailLabel}
                  defaultValue={this.props.currentUserEmail}
                  validate={required}
                />
                <FieldTextInput
                  id="text"
                  name="text"
                  type="textarea"
                  label={helpLabel}
                  placeholder={helpPlaceholder}
                  validate={minLength}
                />
                <Button
                  className={css.submitButton}
                  type="submit"
                  inProgress={submitting}
                  disabled={submitDisabled}
                  ready={false}>
                  <FormattedMessage id="ContactUsPage.submitButton" />
                </Button>
              </Form>
            )
          }}
        />
      </>
    )
  }
}

const { string } = PropTypes

ContactUs.defaultProps = {
  currentUserEmail: ''
}

ContactUs.propTypes = {
  currentUserEmail: string,
  intl: intlShape.isRequired
}

const ContactUsForm = injectIntl(ContactUs)

export default ContactUsForm
