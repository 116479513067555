import React from 'react'
import { TopbarContainer } from '..'
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer
} from '../../components'
import css from './RequestAServicePage.module.css'
import { compose } from 'redux'
import { FormattedMessage, injectIntl } from '../../util/reactIntl'
import { connect } from 'react-redux'
import Page from '../../components/Page/Page'
import RequestServiceForm from '../../forms/RequestServiceForm/RequestServiceForm'

const identity = (v) => v

const RequestAServicePage = (props) => {
  return (
    <Page
      hideContactUs
      title="Request a Service | SkillPickr"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'ContactPage',
        description: 'How can we help?',
        name: 'Request a Service page'
      }}>
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <div className={css.sectionTitle}>
            <h2 className={css.title}>
              <FormattedMessage id="SectionRequestService.requestService" />
            </h2>
            <p className={css.textMuted}>
              <FormattedMessage id="SectionRequestService.titleLineThree" />
            </p>
          </div>
          <br />
          <div className={css.content}>
            <RequestServiceForm currentUserEmail={props.currentUser?.attributes?.email} />
          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  )
}

const mapStateToProps = (state) => {
  const { currentUser } = state.user
  return { currentUser }
}

export default compose(connect(mapStateToProps), injectIntl)(RequestAServicePage)
