import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Field, Form as FinalForm } from 'react-final-form'
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl'
import { Alert, FieldTextInput, Form, InputLabel } from '../../components'

import css from './RequestServiceForm.module.css'
import Button from '../../components/Button/Button'
import { sendRequestServiceEmail } from '../../util/api'
import * as validators from '../../util/validators'
import PhoneInput from 'react-phone-number-input'
import FieldPhoneNumberInput from '../../components/FieldPhoneNumberInput/FieldPhoneNumberInput'

class RequestService extends Component {
  constructor(props) {
    super(props)
    this.onSubmit = this.onSubmit.bind(this)
    this.state = {
      isEmailSent: false
    }
  }

  async onSubmit(values) {
    await sendRequestServiceEmail(values)
    this.setState({ isEmailSent: true })
  }

  render() {
    return (
      <>
        <FinalForm
          {...this.props}
          onSubmit={this.onSubmit}
          render={(formRenderProps) => {
            const { handleSubmit, intl, submitting, invalid, pristine, form } = formRenderProps

            const emailInvalidMessage = intl.formatMessage({
              id: 'PasswordRecoveryForm.emailInvalid'
            })

            const fieldRequiredMessage = intl.formatMessage({
              id: 'ProfileSettingsForm.firstNameRequired'
            })

            const descriptionLabel = intl.formatMessage({
              id: 'RequestService.description'
            })

            const descriptionPlaceholder = intl.formatMessage({
              id: 'ContactUsPage.helpPlaceholder'
            })

            const emailLabel = intl.formatMessage({
              id: 'PasswordRecoveryForm.emailLabel'
            })

            const startDateLabel = intl.formatMessage({
              id: 'BookingDatesForm.bookingStartTitle'
            })

            const endDateLabel = intl.formatMessage({
              id: 'BookingDatesForm.bookingEndTitle'
            })

            const companyLabel = intl.formatMessage({
              id: 'PayoutDetailsForm.companyNameLabel'
            })

            const locationLabel = intl.formatMessage({
              id: 'EditListingLocationPanel.createListingTitle'
            })

            const budgetLabel = intl.formatMessage({
              id: 'RequestService.budget'
            })

            const nameLabel = intl.formatMessage({
              id: 'RequestService.yourName'
            })

            const phoneLabel = intl.formatMessage({
              id: 'PayoutDetailsForm.personalPhoneLabel'
            })

            const submitDisabled = invalid || pristine || submitting
            const emailRequired = validators.emailFormatValid(emailInvalidMessage)
            const required = validators.required(fieldRequiredMessage)
            const minLength = validators.minLength('Min. 20 char', 20)

            return (
              <Form onSubmit={handleSubmit} className={css.form}>
                <div className={css.row}>
                  <FieldTextInput
                    id="email"
                    name="email"
                    type="text"
                    label={`${emailLabel}*`}
                    defaultValue={this.props.currentUserEmail}
                    validate={emailRequired}
                  />
                  <FieldTextInput id="user" name="user" type="text" label={`${nameLabel}*`} validate={required} />
                </div>
                <div className={css.row}>
                  <FieldTextInput id="companyName" name="companyName" type="text" label={companyLabel} />
                  <FieldPhoneNumberInput id="phoneNumber`" name="phoneNumber" label={phoneLabel} />
                </div>
                <div className={css.row}>
                  <FieldTextInput id="startDate" name="startDate" type="text" label={startDateLabel} />
                  <FieldTextInput id="endDate" name="endDate" type="text" label={endDateLabel} />
                </div>
                <div className={css.row}>
                  <FieldTextInput id="location" name="location" type="text" label={locationLabel} />
                  <FieldTextInput id="budget" name="budget" type="text" label={budgetLabel} />
                </div>
                <FieldTextInput
                  id="description"
                  name="description"
                  type="textarea"
                  label={`${descriptionLabel}*`}
                  validate={minLength}
                />
                <div className={css.buttons}>
                  <Button
                    className={css.submitButton}
                    type="submit"
                    inProgress={submitting}
                    disabled={submitDisabled}
                    ready={false}>
                    <FormattedMessage id="StripePaymentForm.submitPaymentInfo" />
                  </Button>
                  {this.state.isEmailSent && (
                    <Alert type="success" className={css.alert}>
                      <FormattedMessage id="ContactUsPage.emailSent"></FormattedMessage>
                    </Alert>
                  )}
                </div>
              </Form>
            )
          }}
        />
      </>
    )
  }
}

const { string } = PropTypes

RequestService.defaultProps = {
  currentUserEmail: ''
}

RequestService.propTypes = {
  currentUserEmail: string,
  intl: intlShape.isRequired
}

const RequestServiceForm = injectIntl(RequestService)

export default RequestServiceForm
