import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from '../../util/reactIntl'
import classNames from 'classnames'

import css from './SectionRequestAService.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '../index'
import { Link } from 'react-router-dom'

const SectionRequestAService = (props) => {
  const { rootClassName, className } = props

  const classes = classNames(rootClassName || css.root, className)

  return (
    <div className={classes}>
      <div>
        <h2 className={css.title}>
          <FormattedMessage id="SectionRequestService.titleLineOne" />
        </h2>
        <p className={css.textMuted}>
          <FormattedMessage id="SectionRequestService.titleLineTwo" />
        </p>
      </div>
      <Link to="/request-a-service" className={classNames(css.heroButton)}>
        <Button>
          <FormattedMessage id="SectionRequestService.requestService" />
        </Button>
      </Link>
    </div>
  )
}

SectionRequestAService.defaultProps = { rootClassName: null, className: null }

const { string } = PropTypes

SectionRequestAService.propTypes = {
  rootClassName: string,
  className: string
}

export default SectionRequestAService
